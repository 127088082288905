<template>
    <registry-simple
      v-show="!isHidden"
      v-if="settings.type === 'registry'"
      :showVerticalLine="showVerticalLine"
      :pageLimit="pageLimit"
      @open-card="addRecord"
      :style="`height: ${height}`"
      :state-id="settings.stateId"
      :showButton="showButton"
      :default-group="defaultGroup"
      :show-search="settings.showSearch"
      :searchAttributes="settings.searchAttributes"
      @edit-record="editRecord"
      :mini="false"
      :headers="settings.columns"
      :cell-click-plugin="settings.cellClickPlugin"
      ref="table"
      :id="settings.registryId"
      :styleTableHeader='styleTableHeader'
      :CSS="CSS"
      :treeTable="treeTable"
    ><slot></slot></registry-simple>
    <simple-table
      v-else
      v-show="!isHidden"
      :showButton="showButton"
      :style="`height: ${height}`"
      v-on:input="$emit('input', $event)"
      ref="table"
      @edit-record="openRecordEditor"
      :cell-click-plugin="settings.cellClickPlugin"
      :show-summary="settings.show_summary"
      :show-pagination="settings.show_pagination"
      :fast-add="settings.fast_add"
      :loader="this.loaderData"
      :columns="settings.columns"
      :is-selectable="settings.is_selectable"
      :styleTableHeader='styleTableHeader'
    ><slot></slot></simple-table>
</template>

<script>
import mixin from '../mixins'
import RegistrySimple from '@/components/Registry/RegistryTable'
import SimpleTable from './a-simple-table'
import VisibleMixin from '../visible_properties_mixin'
export default {
  mixins: [mixin, VisibleMixin],
  components: {
    RegistrySimple,
    SimpleTable
  },
  name: 'a-table',
  inject: ['openRegistryCard', 'openDashboardCard'],
  props: {
    value: {
      frozen: true
    },
    styleTableHeader: {
      type: String,
      description: 'CSS стили шапки таблицы',
      default: () => {
        return 'font-style: normal; font-weight: normal; font-size: 13px; line-height: 20px; word-break: break-word; color: #807265'
      }
    },
    filters: {
      type: Array,
      editor: 'Filters'
    },
    defaultGroup: {
      description: 'Группировка (attr_N_)',
      type: String
    },
    pageLimit: {
      type: Number,
      description: 'Количество записей на странице'
    },
    name: {
      type: String,
      description: 'Атрибут'
    },
    extendedToBottom: {
      type: Boolean,
      description: 'Растягивать вниз',
      default: true
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },
    treeTable: {
      type: Boolean,
      description: 'Древовидная таблица',
      default: false
    },
    settings: {
      type: Object,
      default: () => {
        return {
          type: 'registry',
          registryId: null,
          columns: [],
          is_selectable: true,
          stateId: null,
          plugin: null,
          fast_add: false,
          show_pagination: true,
          show_summary: false,
          filters: [],
          editor: null,
          searchAttributes: null,
          showSearch: false,
          cellClickPlugin: null
        }
      },
      editor: 'Table'
    },
    showButton: {
      type: Object,
      default: () => {
        return {
          update: false,
          add: false,
          delete: false,
          export: false,
          import: false,
          views: false,
          group: false,
          filter: false,
          edit: false
        }
      },
      editor: 'ShowButton',
      description: 'Видимость кнопок'
    },
    showVerticalLine: {
      type: Boolean,
      description: 'Убрать вертикальные линии'
    }
  },
  data () {
    return {
      card: null,
      rendered: false,
      observer: undefined
    }
  },
  watch: {
    dataFilters () {
      if (this.settings.type === 'registry') {
        this.loadData()
      }
    }
  },
  beforeDestroy () {
    this.observer.unobserve(this.$el)
  },
  mounted () {
    this.$nextTick(() => {
      this.rendered = true
      let me = this
      let respondToVisibility = function (element, callback) {
        let options = {
          root: document.documentElement
        }

        me.observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            callback(entry.intersectionRatio > 0)
          })
        }, options)

        me.observer.observe(element)
      }
      respondToVisibility(this.$el, visible => {
        if (visible) {
          if (this.$refs.table) {
            setTimeout(() => this.$refs.table.$refs.table.doLayout(), 100)
          }
        }
      })
      if (this.$refs.table) {
        this.$refs.table.$refs.table.doLayout()
      }
    })
    if (this.settings.type === 'registry' && this.settings.registryId) {
      this.$http.get(`${this.$config.api}/interfaceeditor/cards?entity_id=${this.settings.registryId}&fields=id,name,is_default`)
        .then(response => {
          let card = response.data.filter(item => item.is_default)
          this.card = card[0]
          this.loadData()
        })
    }
  },
  computed: {
    height () {
      if (this.extendedToBottom && !this.isHidden && this.rendered) {
        return 'calc(100% - ' + this.$el.offsetTop + 'px)'
      }
      return '100%'
    },
    dataFilters () {
      let filters = []
      if (this.filters) {
        this.filters.forEach(item => {
          let type = 'eq'
          if (item.isXref) {
            type = 'eqx'
          }
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias && this.getModel()[item.attribute] + '') {
              filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
            }
          } else if (item.type === 'constant' && item.alias) {
            filters.push(`${item.alias},${type},${item.attribute}`)
          } else if (item.type === 'current_user' && item.alias) {
            filters.push(`${item.alias},${type},${this.$store.getters['Authorization/userId']}`)
          }
        })
      }
      return filters
    },
    loaderData () {
      let filters = []
      if (this.settings.filters) {
        this.settings.filters.forEach(item => {
          if (this.getModel()[item.attribute] && item.alias) {
            filters.push(`${item.alias}=${this.getModel()[item.attribute]}`)
          }
        })
      }
      let loader = dataFilter => {
        if (this.settings.plugin) {
          return this.$http.get(`${this.$config.api}/registryservice/plugins/execute/${this.settings.plugin}?${dataFilter.join('&')}`)
        } else if (this.value) {
          return { data: this.value }
        }
        return null
      }

      return () => loader(filters)
    }
  },
  methods: {
    loadData () {
      this.$refs.table.clearFilters()
      this.dataFilters.forEach(item => {
        this.$refs.table.addFilter(item)
      })
      this.$refs.table.loadData()
    },
    openRecordEditor (data) {
      if (!data.id) {
        console.warn('bad id parameter')
      }
      this.openDashboardCard(this.settings.editor, 'test', data.id, data)
    },
    async getRegistryCardId (recordId) {
      let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.settings.registryId}/records/${recordId}/card`)
      return data.data[0]
    },
    async editRecord (data) {
      let card = this.card
      if (data.id) {
        this.$set(data, 'loading', true)
        card = await this.getRegistryCardId(data.id)
        this.$set(data, 'loading', false)
      }
      if (!card) {
        return false
      }
      this.openRegistryCard({
        registryId: this.settings.registryId,
        cardId: card.id,
        cardName: card.name,
        recordId: data.id,
        initialData: {},
        registry: this.$refs.table
      })
    },
    addRecord () {
      if (!this.card) {
        return false
      }
      this.openRegistryCard({
        registryId: this.settings.registryId,
        cardId: this.card.id,
        cardName: this.card.name,
        recordId: null,
        initialData: {},
        registry: this.$refs.table
      })
    }
  }
}
</script>

<style scoped>
</style>
